<template>
  <div id="app">
    <v-app>
      <v-banner
        v-if="deferredPrompt"
        color="info"
        dark
        class="text-left"
      >
        Get our free app. It won't take up space on your phone and also works offline!
        
        <template v-slot:actions>
          <v-btn text @click="dismiss">Dismiss</v-btn>
          <v-btn text @click="install">Install</v-btn>
        </template>
      </v-banner>

      <div class="pa-4 text-center">
        <img alt="Logo" src="./assets/logo.png" class="logo" />

        <div>
          <audio ref="player">
            <source src="https://streaming.shoutcast.com/sri-guru-singh-sabha-derby" type="audio/mpeg" />
            Cannot load audio player.
          </audio>
          <v-btn color="primary" fab large dark @click="play" v-if="!isPlaying">
                <v-icon>mdi-play</v-icon>
          </v-btn>
          <v-btn color="primary" fab large dark @click="pause" v-if="isPlaying" class="pause-btn">
                <v-icon>mdi-pause</v-icon>
          </v-btn>
          <v-btn color="primary" fab large dark @click="stop" v-if="isPlaying">
                <v-icon>mdi-stop</v-icon>
          </v-btn>
        </div>
      </div>

      <v-alert prominent type="error" v-if="isOffline" class="missing-internet">
          ਨੈਟ ਮੌਜੂਦ ਨਹੀਂ ਹੈ. Internet not available.
      </v-alert>

      <div class="version">v0.5</div>
    </v-app>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import Cookies from "js-cookie";

export default {
  name: 'App',

  data() {
    return {
      deferredPrompt: null,
      isPlaying: false,
      isOffline: false
    };
  },
  created() {
    document.title = "Singh Sabha Radio Derby"
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
          this.deferredPrompt = null;
        });

    this.isOffline = !navigator.onLine;

    window.addEventListener('online',  () => this.isOffline = false);
    window.addEventListener('offline', () => this.isOffline = true);
  },
  mounted() {
    this.$refs.player.onplay = () => {
      this.isPlaying = true;
    };
    this.$refs.player.onerror = () => {
      this.isPlaying = false;
    };
    this.$refs.player.onpause = () => {
      this.isPlaying = false;
    };
  },
  methods: {
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 15 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    play() {
      this.$refs.player.play();
    },
    pause() {
      this.$refs.player.pause();
    },
    stop() {
      const src = this.$refs.player.src
      this.$refs.player.src = src;
      this.isPlaying = false;
    }
  }
};
</script>
<style scoped>
.v-application{
    background: #ffc30d !important;
}
.logo {
  width: 100%;
  max-width: 400px;
  margin: 3rem 0;
  padding: 0 2rem;
}

.pause-btn {
  margin-right: 0.5rem;
}

.missing-internet {
  margin: 0 1rem;
}

.version {
    position: absolute;
    bottom: 10px;
    font-size: 0.7rem;
    color: #2e4d8e;
    width: 100%;
    text-align: center;
}
</style>